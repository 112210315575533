import { faBan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import type { FunctionComponent, ReactElement } from 'react'
import type { MessageItem } from '../../containers/ChatMessage'
import { ChatBanButton, MessageContent, MessageHeader, MessageTime, MessageWrapper, UserName } from '../../containers/ChatMessage'
import type { ChatMessage } from '../../redux/webSocket/interfaces/ChatMessage'

interface Props {
	message: ChatMessage
	isBanDisabled: boolean
	handleBanClick(): void
}

function ChatMessageItem({ message, isBanDisabled, handleBanClick }: Props): ReactElement<typeof MessageItem, FunctionComponent<typeof MessageItem>> {
	const { name, content, time, isRemoved } = message

	return (
		<>
			<MessageWrapper isRemoved={isRemoved}>
				<MessageHeader>
					<UserName>{name}</UserName>
					<MessageTime>{time}</MessageTime>
				</MessageHeader>

				<MessageContent>{content}</MessageContent>
			</MessageWrapper>
			<ChatBanButton disabled={isBanDisabled} onClick={handleBanClick}>
				<FontAwesomeIcon icon={faBan} size="1x"></FontAwesomeIcon>
			</ChatBanButton>
		</>
	)
}

export default ChatMessageItem
