import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'
import { Localization } from 'react-widgets'
import { DateLocalizer } from 'react-widgets/IntlLocalizer'
import { tooltipId } from '../../config/const'
import { GlobalStyles } from '../../config/styles/global'
import { darkTheme } from '../../config/styles/theme'
import { PanelContainer } from '../../containers/Panel/Panel'
import { useToken } from '../../utils/hooks/useToken'
import { useWebSocket } from '../../utils/hooks/useWebSocket'
import Auth from '../Auth'
import CommandsMenu from '../CommandsMenu'
import Dashboard from '../Dashboard'
import LiveChat from '../LiveChat'
import Log from '../Log'

function App() {
	useWebSocket()
	const token = useToken()

	return (
		<Localization date={new DateLocalizer({ culture: 'en-NO', firstOfWeek: 1 })}>
			<GlobalStyles theme={darkTheme} />
			{!token ? (
				<Auth />
			) : (
				<PanelContainer>
					<CommandsMenu />
					<Dashboard />
					<Log />
					<LiveChat />
					<Tooltip id={tooltipId} />
				</PanelContainer>
			)}
		</Localization>
	)
}

export default App
