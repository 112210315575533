import type { Dispatch } from 'redux'
import { sendCommandToStudio } from '../../../services/api/serverless'
import type { StateTree } from '../../interfaces'
import type { PickWinnersFailureAction } from '../interfaces/actions/PickWinnersFailureAction'
import type { PickWinnersStartAction } from '../interfaces/actions/PickWinnersStartAction'
import type { PickWinnersSuccessAction } from '../interfaces/actions/PickWinnersSuccessAction'
import type { PickWinnersToggleAction } from '../interfaces/actions/PickWinnersToggleAction'
import type { PickWinners } from '../interfaces/PickWinners'
import { REDUX_STUDIO_COMMAND_TYPES } from '../types'

const pickWinnersStart = (): PickWinnersStartAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.PICK_WINNERS_START }
}

const pickWinnersFailure = (): PickWinnersFailureAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.PICK_WINNERS_FAILURE }
}

const pickWinnersDisable = (): PickWinnersToggleAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.PICK_WINNERS_TOGGLE, isAvailable: false }
}

const pickWinnersSuccess = (): PickWinnersSuccessAction => {
	return { type: REDUX_STUDIO_COMMAND_TYPES.PICK_WINNERS_SUCCESS }
}

export default function pickWinners() {
	return async (dispatch: Dispatch, getState: () => StateTree): Promise<void> => {
		dispatch(pickWinnersStart())

		try {
			const {
				dashboardInputs: { prizes, seasonWinnerState },
				commands: {
					seasonTransition: { seasonTransitionStatus: seasonTransition },
					showType: { showType },
				},
			} = getState()

			const {
				currency,
				beatGuestWinner: { value: liveShowPrize },
				luckyWinner: { value: randomPrize },
				coLuckyWinner: { value: coLuckyWinner },
				leagueWinner: { value: leagueWinner },
			} = prizes

			const pickWinnersCommand: PickWinners = {
				command: 'pickWinners',
				currency,
				liveShowPrize,
				randomPrize,
				tournamentPrizes: {
					'beat-guest-winner': liveShowPrize,
					'lucky-winner': randomPrize,
					'co-lucky-winner': coLuckyWinner,
					'league-winner': leagueWinner,
				},
				showType,
				seasonTransition,
				seasonWinnerUserId: seasonTransition ? seasonWinnerState.userId : '',
			}

			await sendCommandToStudio(pickWinnersCommand)
			dispatch(pickWinnersSuccess())
			dispatch(pickWinnersDisable())
		} catch (e) {
			dispatch(pickWinnersFailure())
		}
	}
}
