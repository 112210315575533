import type { FunctionComponent, ReactElement } from 'react'
import mario from '../../assets/images/mario.gif'
import { tooltipId } from '../../config/const'
import { useExecuteShow } from '../../utils/hooks/commands/useExecuteShow'
import { ExecuteAutomationButtonContainer } from './ExecuteAutomationButtonContainer'

function ExecuteAutomationButton(): ReactElement<
	typeof ExecuteAutomationButtonContainer,
	FunctionComponent<typeof ExecuteAutomationButtonContainer>
> {
	const [isAutomationRunning, executeShow] = useExecuteShow()

	return (
		<>
			<ExecuteAutomationButtonContainer
				onClick={executeShow}
				data-tooltip-content="Push and confirm 10 seconds before the current tournament ends"
				data-tooltip-variant="warning"
				data-tooltip-id={tooltipId}
			>
				{isAutomationRunning ? 'Abort Automation' : 'Execute show'}
				{isAutomationRunning && <img src={mario} alt="" width="20px" />}
			</ExecuteAutomationButtonContainer>
		</>
	)
}

export default ExecuteAutomationButton
