import type { FunctionComponent, ReactElement } from 'react'
import { CardBody, CardHeader, CardHeading, CardWrapper } from '../../containers/Card'
import NewsList from '../NewsList'

function News(): ReactElement<typeof CardWrapper, FunctionComponent<typeof CardWrapper>> {
	return (
		<CardWrapper style={{ width: '60%' }}>
			<CardHeader>
				<CardHeading>News</CardHeading>
			</CardHeader>
			<CardBody>
				<NewsList isActive={true} />
				<NewsList isActive={false} />
			</CardBody>
		</CardWrapper>
	)
}

export default News
