import type { FunctionComponent, ReactElement } from 'react'
import { appEnv } from '../../config'
import Column from '../../containers/Column'
import Row from '../../containers/Row'
import type { StateTree } from '../../redux/interfaces'
import { useAppSelector } from '../../utils/hooks'

function DashboardHeader(): ReactElement<typeof Row, FunctionComponent<typeof Row>> {
	const { country } = useAppSelector(({ country: { value: country } }: StateTree) => ({ country }))
	return (
		<Row>
			<Column>
				<h1>
					Live Operations Panel ({appEnv.toUpperCase()} - {country.toUpperCase()})
				</h1>
			</Column>
		</Row>
	)
}

export default DashboardHeader
