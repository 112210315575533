import { analyticsCuePointTitles, timestampAutomationCommandTitles } from '../config/app'
import { Game } from '../config/enums'
import type { StateTree } from '../redux/interfaces'
import type { ShowInformation } from '../redux/studioCommand/interfaces/ShowInformation'
import { parseBaseGameSettingsValues, parseBlockbusterSettingsValues } from './getGameSettingsValues'
import { getNextGoals } from './getNextGoals'
import { getNextShowTime } from './getNextShowTime'

export const getShowInformation = (stateTree: StateTree): ShowInformation => {
	const {
		dashboardInputs: {
			video,
			beatGuestMessages,
			prizes,
			blockbusterSettings,
			centipedeSettings,
			beatSettings,
			rallySettings,
			breakoutSettings,
			featuredGame,
			nextDailyGoals,
			nextWeeklyGoals,
			nextMonthlyGoals,
			timestampsAutomationSettings,
			analyticsCuePointsState: { props: analyticsCuePoints },
			guestInfo,
			seasonWinnerState,
		},
		commands: {
			leaguesReset: { leaguesResetStatus: leaguesReset },
			seasonTransition: { seasonTransitionStatus: seasonTransition },
			showType: { showType },
		},
		showCalendar: { selectedDate },
	} = stateTree

	const {
		highQualityVideoUrl: { value: highQualityVideoUrl },
		videoUrl: { value: videoUrl },
		lowQualityVideoUrl: { value: lowQualityVideoUrl },
		guestScore: { value: guestScore },
	} = video
	const {
		yesFirst: { value: yesFirst },
		yesSecond: { value: yesSecond },
		noFirst: { value: noFirst },
		noSecond: { value: noSecond },
	} = beatGuestMessages
	const {
		currency,
		beatGuestWinner: { value: beatGuestWinner },
		luckyWinner: { value: luckyWinner },
		coLuckyWinner: { value: coLuckyWinner },
		leagueWinner: { value: leagueWinner },
	} = prizes

	return {
		video: { highQualityVideoUrl, videoUrl, lowQualityVideoUrl, guestScore },
		beatGuestMessages: { yesFirst, yesSecond, noFirst, noSecond },
		prizes: { currency, beatGuestWinner, luckyWinner, coLuckyWinner, leagueWinner },
		gameSettings: {
			[Game.Blockbuster]: parseBlockbusterSettingsValues(blockbusterSettings),
			[Game.Centipede]: parseBaseGameSettingsValues(centipedeSettings, Game.Centipede),
			[Game.Beat]: parseBaseGameSettingsValues(beatSettings, Game.Beat),
			[Game.Rally]: parseBaseGameSettingsValues(rallySettings, Game.Rally),
			[Game.Breakout]: parseBaseGameSettingsValues(breakoutSettings, Game.Breakout),
		},
		gameSettingsInputs: {
			[Game.Blockbuster]: {
				shots: blockbusterSettings.shots.value,
				level: blockbusterSettings.level.value,
				stage: blockbusterSettings.stage.value,
			},
			[Game.Centipede]: {
				level: centipedeSettings.level.value,
				stage: centipedeSettings.stage.value,
			},
			[Game.Beat]: {
				level: beatSettings.level.value,
				stage: beatSettings.stage.value,
			},
			[Game.Rally]: {
				level: rallySettings.level.value,
				stage: rallySettings.stage.value,
			},
			[Game.Breakout]: {
				level: breakoutSettings.level.value,
				stage: breakoutSettings.stage.value,
			},
		},
		featuredGame,
		nextGoals: getNextGoals({ nextDailyGoals, nextWeeklyGoals, nextMonthlyGoals }),
		nextGoalsInputs: {
			nextDailyGoals: nextDailyGoals.value,
			nextWeeklyGoals: nextWeeklyGoals.reset ? nextWeeklyGoals.value : undefined,
			nextMonthlyGoals: nextMonthlyGoals.reset ? nextMonthlyGoals.value : undefined,
		},
		nextShowTimeStamp: getNextShowTime(selectedDate, true).valueOf(),
		timestampAutomationSettings: timestampsAutomationSettings.value,
		timestampAutomationCommandTitles,
		analyticsCuePoints,
		analyticsCuePointTitles,
		nextGuestPosterInfo: {
			guestRoomPosterPictureURL: guestInfo.guestRoomPosterPictureURL.value,
			guestDetailsPictureURL: guestInfo.guestDetailsPictureURL.value,
			guestTitle: guestInfo.guestTitle.value,
			guestDetails: guestInfo.guestDetails.value,
			guestExternalURL: guestInfo.guestExternalURL.value,
		},
		leaguesReset,
		seasonTransition,
		showType,
		seasonWinnerUserId: seasonTransition ? seasonWinnerState.userId : '',
	}
}
