import type { FunctionComponent, ReactElement } from 'react'
import { analyticsCuePointTitles } from '../../config/app'
import type { AnalyticsCuePoints } from '../../config/enums'
import { CardBody, CardFieldSet, CardHeader, CardHeading, CardWrapper } from '../../containers/Card'
import { maxMinuteSecondNumber, minTimeNumber, useAnalyticsCuePoints } from '../../utils/hooks/dashboardInputs/useAnalyticsCuePoints'

function AnalyticsCuePointsElement(): ReactElement<typeof CardWrapper, FunctionComponent<typeof CardWrapper>> {
	const [analyticsCuePoints, onTimestampSettingChange] = useAnalyticsCuePoints()

	return (
		<CardWrapper>
			<CardHeader>
				<CardHeading>Analytics cue points</CardHeading>
			</CardHeader>
			<CardBody>
				<CardFieldSet>
					<table style={{ margin: '0 auto' }}>
						<thead>
							<tr>
								<th>Cue point</th>
								<th>Minute</th>
								<th>Second</th>
							</tr>
						</thead>
						<tbody>
							{Object.entries(analyticsCuePoints).map(([keyProperty, { minute, second }], index) => {
								const cuePoint = keyProperty as AnalyticsCuePoints
								const title = analyticsCuePointTitles[cuePoint]
								const onChangeFunction = onTimestampSettingChange(cuePoint)

								return (
									<tr key={`${cuePoint}-${index}`}>
										<td>{title}</td>
										<td>
											<input
												type="number"
												value={minute}
												name="minute"
												min={minTimeNumber}
												max={maxMinuteSecondNumber}
												onChange={onChangeFunction}
											/>
										</td>
										<td>
											<input
												type="number"
												value={second}
												name="second"
												min={minTimeNumber}
												max={maxMinuteSecondNumber}
												onChange={onChangeFunction}
											/>
										</td>
									</tr>
								)
							})}
						</tbody>
					</table>
				</CardFieldSet>
			</CardBody>
		</CardWrapper>
	)
}

export default AnalyticsCuePointsElement
