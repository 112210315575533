import type { ReactElement, FunctionComponent } from 'react'
import { useRef } from 'react'
import type { VirtuosoHandle } from 'react-virtuoso'
import { Virtuoso } from 'react-virtuoso'
import { MessageItem, MessagesContainer } from '../../containers/ChatMessage'
import type { ChatMessage } from '../../redux/webSocket/interfaces/ChatMessage'
import { useChatBanPlayer } from '../../utils/hooks/useChatBanPlayer'
import ChatMessageItem from './ChatMessageItem'

interface Props {
	messages: ChatMessage[]
	followNewMessages: boolean
}

const VirtuosoComponents = { Scroller: MessagesContainer, Item: MessageItem }
function ChatMessages({ messages, followNewMessages }: Props): ReactElement<typeof Virtuoso, FunctionComponent<typeof Virtuoso>> {
	const virtuosoRef = useRef<VirtuosoHandle>(null)
	const [tryingToBan, chatBanHandle] = useChatBanPlayer()

	const isBanDisabled = ({ playFabId, isRemoved }: ChatMessage) => isRemoved || playFabId === 'studio' || tryingToBan.includes(playFabId)

	return (
		<Virtuoso
			style={{ height: 300 }}
			atBottomThreshold={5}
			ref={virtuosoRef}
			data={messages}
			itemContent={(_, message) => (
				<ChatMessageItem
					key={message.id}
					message={message}
					isBanDisabled={isBanDisabled(message)}
					handleBanClick={() => chatBanHandle(message)}
				/>
			)}
			followOutput={() => {
				return followNewMessages
			}}
			components={VirtuosoComponents}
		/>
	)
}

export default ChatMessages
