import { nanoid } from 'nanoid'
import type { ChangeEvent, FunctionComponent, ReactElement } from 'react'
import { HeaderItem, NewsListContainer, NewsListContainerHeader, NewsListHeader, NewsListUL } from '../../containers/News'
import type { Article, ArticleImage, Articles, ArticleText, NewsArticlesConfig } from '../../redux/news/interfaces/NewsState'
import { Alignment, Template, TextColor, TextFont } from '../../redux/news/interfaces/NewsState'
import { useNewsState } from '../../utils/hooks/useNewsState'
import moveArrayElementPosition from '../../utils/moveArrayElementPosition'
import NewListItem from '../NewsListItem'

interface Props {
	isActive: boolean
}

function NewsList({ isActive }: Props): ReactElement<typeof NewsListContainer, FunctionComponent<typeof NewsListContainer>> {
	const [{ data: news }, updateState] = useNewsState()

	const { config = {} as NewsArticlesConfig, articles = {} as Articles } = news

	const defaultTextFields = {
		text: '',
		alignment: Alignment.Default,
		color: TextColor.Default,
		font: TextFont.Default,
	}

	const defaultImageField = { link: '', alignment: Alignment.Default }

	const indexOfArticle = (articleId: string) => {
		return config?.order?.indexOf(articleId) || 0
	}

	const onChangeIsCritical = (articleId: string) => (): void => {
		const currentArticle = articles?.[articleId]
		const newArticle = { ...currentArticle, isCritical: !currentArticle.isCritical }

		updateState({ config, articles: { ...articles, [articleId]: newArticle } })
	}

	const onChangeArticleName =
		(articleId: string) =>
		(event: ChangeEvent<HTMLInputElement>): void => {
			const {
				currentTarget: { value },
			} = event

			const currentArticle = articles?.[articleId]
			currentArticle.name = value

			updateState({ config, articles: { ...articles, [articleId]: currentArticle } })
		}

	const onChangeTemplate =
		(articleId: string) =>
		(event: ChangeEvent<HTMLSelectElement>): void => {
			const {
				currentTarget: { value },
			} = event

			const currentArticle = articles?.[articleId]
			currentArticle.templateId = Number(value)

			updateState({ config, articles: { ...articles, [articleId]: currentArticle } })
		}

	const onChangeArticleHeader =
		(articleId: string) =>
		(index: number) =>
		(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void => {
			const {
				currentTarget: { value, name },
			} = event

			const propertyName = name as keyof ArticleText

			const currentArticle = articles?.[articleId]
			currentArticle.headers[index][propertyName] = propertyName !== 'text' ? (Number(value) as never) : (value as never)

			updateState({ config, articles: { ...articles, [articleId]: currentArticle } })
		}

	const onChangeArticleSubHeader =
		(articleId: string) =>
		(index: number) =>
		(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void => {
			const {
				currentTarget: { value, name },
			} = event

			const propertyName = name as keyof ArticleText

			const currentArticle = articles?.[articleId]
			currentArticle.subHeaders[index][propertyName] = propertyName !== 'text' ? (Number(value) as never) : (value as never)

			updateState({ config, articles: { ...articles, [articleId]: currentArticle } })
		}

	const onChangeArticleMessage =
		(articleId: string) =>
		(index: number) =>
		(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>): void => {
			const {
				currentTarget: { value, name },
			} = event

			const propertyName = name as keyof ArticleText

			const currentArticle = articles?.[articleId]
			currentArticle.messages[index][propertyName] = propertyName !== 'text' ? (Number(value) as never) : (value as never)

			updateState({ config, articles: { ...articles, [articleId]: currentArticle } })
		}

	const onChangeArticleFooter =
		(articleId: string) =>
		(index: number) =>
		(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void => {
			const {
				currentTarget: { value, name },
			} = event

			const propertyName = name as keyof ArticleText

			const currentArticle = articles?.[articleId]
			currentArticle.footers[index][propertyName] = propertyName !== 'text' ? (Number(value) as never) : (value as never)

			updateState({ config, articles: { ...articles, [articleId]: currentArticle } })
		}

	const onChangeArticleImage =
		(articleId: string) =>
		(index: number) =>
		(event: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>): void => {
			const {
				currentTarget: { value, name },
			} = event

			const propertyName = name as keyof ArticleImage

			const currentArticle = articles?.[articleId]
			currentArticle.images[index][propertyName] = propertyName !== 'link' ? (Number(value) as never) : (value as never)

			updateState({ config, articles: { ...articles, [articleId]: currentArticle } })
		}

	const onNewClick = (): void => {
		const articleId = nanoid()

		const newArticle = {
			id: articleId,
			name: '',
			createdAt: Date.now(),
			updatedAt: Date.now(),
			templateId: Template.Template1,
			isActive: true,
			headers: [{ ...defaultTextFields }],
			subHeaders: [{ ...defaultTextFields }],
			messages: [{ ...defaultTextFields }],
			footers: [{ ...defaultTextFields }],
			images: [{ ...defaultImageField }],
		}

		updateState({ config: { ...config, order: [...config.order, articleId] }, articles: { ...articles, [articleId]: newArticle } })
	}

	const onAddField = (articleId: string) => (fieldType: 'headers' | 'subHeaders' | 'messages' | 'footers' | 'images') => (): void => {
		const currentArticle = articles?.[articleId]

		const newListOfType = [...currentArticle[fieldType]]
		if (fieldType === 'images') {
			newListOfType.push({ ...defaultImageField })
		} else {
			newListOfType.push({ ...defaultTextFields })
		}

		updateState({ config, articles: { ...articles, [articleId]: { ...currentArticle, [fieldType]: newListOfType } } })
	}

	const onActivationClick = (articleId: string) => (): void => {
		const currentArticle = articles?.[articleId]
		const newArticle = { ...currentArticle, isActive: !currentArticle.isActive }

		let newOrder = [...config.order]
		if (!newArticle.isActive) {
			newOrder = newOrder.filter((id) => id !== articleId)
			newArticle.isCritical = false
		} else {
			newOrder.push(articleId)
		}

		updateState({ config: { ...config, order: newOrder }, articles: { ...articles, [articleId]: newArticle } })
	}

	const onChangeOrder =
		(articleId: string) =>
		(event: ChangeEvent<HTMLInputElement>): void => {
			const {
				currentTarget: { value },
			} = event

			const newOrder = moveArrayElementPosition([...config.order], articleId, Number(value))

			updateState({ config: { ...config, order: newOrder }, articles })
		}

	return (
		<NewsListContainer>
			<NewsListContainerHeader>{`${isActive ? 'Active' : 'Inactive'} news`}</NewsListContainerHeader>

			<NewsListHeader>
				<HeaderItem width="10%">Order</HeaderItem>
				<HeaderItem width="25%">Template</HeaderItem>
				<HeaderItem width="25%">News name</HeaderItem>
				<HeaderItem width="15%">Date</HeaderItem>
				{isActive && (
					<HeaderItem width="15%">
						<button onClick={onNewClick}>New</button>
					</HeaderItem>
				)}
			</NewsListHeader>

			<NewsListUL>
				{Object.keys(articles)
					.filter((key) => articles?.[key].isActive === isActive)
					.sort((a, b) => config.order.indexOf(a) - config.order.indexOf(b))
					.map((key, index) => (
						<NewListItem
							position={indexOfArticle(key)}
							onChangeArticleName={onChangeArticleName(key)}
							onChangeTemplate={onChangeTemplate(key)}
							onChangeArticleHeader={onChangeArticleHeader(key)}
							onChangeArticleSubHeader={onChangeArticleSubHeader(key)}
							onChangeArticleMessage={onChangeArticleMessage(key)}
							onChangeArticleFooter={onChangeArticleFooter(key)}
							onChangeArticleImage={onChangeArticleImage(key)}
							onActivationClick={onActivationClick(key)}
							onAddField={onAddField(key)}
							onChangeIsCritical={onChangeIsCritical(key)}
							onChangeOrder={onChangeOrder(key)}
							key={`${articles?.[key].id}-${index}`}
							article={articles?.[key] || ({} as Article)}
						/>
					))}
			</NewsListUL>
		</NewsListContainer>
	)
}
export default NewsList
