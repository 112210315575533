import type { ChangeEvent, FunctionComponent, KeyboardEvent, MouseEvent, ReactElement } from 'react'
import { Fragment, useState } from 'react'
import { appEnv } from '../../config'
import type { Country } from '../../config/interfaces/Country'
import { useAuth } from '../../utils/hooks/useAuth'
import { useCountry } from '../../utils/hooks/useCountry'
import CountrySelect from '../CountrySelect'
import { AuthContainer } from './AuthContainer'

function Auth(): ReactElement<typeof AuthContainer, FunctionComponent<typeof AuthContainer>> {
	const [studioKey, setStudioKey] = useState('')
	const authenticate = useAuth()
	const { selectedCountry, updateCountry } = useCountry()

	const onChangeStudioKey = (event: ChangeEvent<HTMLInputElement>): void => {
		const {
			currentTarget: { value },
		} = event

		setStudioKey(value)
	}

	const onChangeCountry = (event: ChangeEvent<HTMLSelectElement>): void => {
		const {
			currentTarget: { value },
		} = event

		updateCountry(value as Country)
	}

	const onKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
		const { key } = event

		if (key === 'Enter') {
			authenticate(studioKey)
		}
	}

	const onSignInClick = (event: MouseEvent<HTMLButtonElement>): void => {
		event.stopPropagation()
		authenticate(studioKey)
	}

	const inputType = 'password'

	return (
		<AuthContainer app_env={appEnv}>
			<Fragment>
				<label>Key</label>
				<input
					autoFocus
					type={inputType}
					name={inputType}
					id={inputType}
					value={studioKey}
					onChange={onChangeStudioKey}
					onKeyDown={onKeyDown}
				/>
				<CountrySelect keyValue="country-select" value={selectedCountry.value} onChange={onChangeCountry} />
			</Fragment>
			<Fragment>
				<button onClick={onSignInClick}>Sign in</button>
			</Fragment>
		</AuthContainer>
	)
}

export default Auth
