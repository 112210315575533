import type { PrizesState } from '../../redux/dashboardInputs/interfaces/PrizesState'
import type { PrizesValidActionProps } from '../../redux/dashboardInputs/interfaces/PrizesValidActionProps'
import { isLeaguePrizeValueValid, isPrizeValueValid } from './isPrizeValueValid'

export const validatePrizes = ({ beatGuestWinner, luckyWinner, coLuckyWinner, leagueWinner }: PrizesState): PrizesValidActionProps => {
	const isBeatGuestWinnerValid = isPrizeValueValid(beatGuestWinner)
	const isLuckyWinnerValid = isPrizeValueValid(luckyWinner)
	const isCoLuckyWinnerValid = isPrizeValueValid(coLuckyWinner)
	const isLeagueWinnerValid = isLeaguePrizeValueValid(leagueWinner)

	return {
		isBeatGuestWinnerValid,
		isLuckyWinnerValid,
		isCoLuckyWinnerValid,
		isLeagueWinnerValid,
	}
}
