import { faBroom, faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Console, Hook, Unhook } from 'console-feed-optimized'
import type { Message as ComponentMessage } from 'console-feed-optimized/lib/definitions/Component'
import type { Message as ConsoleMessage } from 'console-feed-optimized/lib/definitions/Console'
import type { FunctionComponent, ReactElement } from 'react'
import { useEffect, useRef, useState } from 'react'
import { LiveChatHeader, LiveChatHeading } from '../LiveChat/LiveChatContainer'
import { LogBody, LogButton, LogClearButton, LogContainer, LogExportLink, LogFooter, LogWrapper } from './LogContainer'

function Log(): ReactElement<typeof LogContainer, FunctionComponent<typeof LogContainer>> {
	const logsInitialState: ConsoleMessage[] = []
	const logFileName = 'liveOps-logs.txt'
	const [logs, setLogs] = useState<ConsoleMessage[]>(logsInitialState)
	const [isActive, setIsActive] = useState(false)
	const logFileRef = useRef<HTMLAnchorElement>(null)

	useEffect(() => {
		const hookedConsole = Hook(window.console, (log: ConsoleMessage) => setLogs((currLogs) => [...currLogs, log]), false)

		return (): void => {
			Unhook(hookedConsole)
		}
	}, [])

	useEffect(() => {
		if (!logFileRef?.current) {
			return
		}

		const logsToExport = logs.map(({ data, method }) => {
			const [log] = data || ['']
			const dataToLog = typeof log === 'object' ? JSON.stringify(log) : log
			return [`${method}: `, dataToLog, '\n']
		})

		const file = new Blob(logsToExport.flat(), { type: 'text/plain' })
		logFileRef.current.href = window.URL.createObjectURL(file)
		logFileRef.current.download = logFileName
	}, [logs, logFileRef])

	const toggleChat = (): void => {
		setIsActive(!isActive)
	}

	const clearLogs = (): void => {
		setLogs(logsInitialState)
	}

	return (
		<LogContainer>
			{isActive && (
				<LogWrapper>
					<LiveChatHeader onClick={toggleChat}>
						<LiveChatHeading>Logs</LiveChatHeading>
					</LiveChatHeader>
					<LogBody>
						<Console
							logs={logs as ComponentMessage[]}
							styles={{ LOG_COLOR: 'white', LOG_INFO_COLOR: 'lightgreen', LOG_ERROR_COLOR: 'orangered' }}
						/>
					</LogBody>
					<LogFooter>
						<LogExportLink ref={logFileRef} href={logFileName}>
							<FontAwesomeIcon icon={faDownload} size="sm" />
						</LogExportLink>
						<LogClearButton onClick={clearLogs}>
							<FontAwesomeIcon icon={faBroom} size="sm" />
						</LogClearButton>
					</LogFooter>
				</LogWrapper>
			)}
			<LogButton onClick={toggleChat}>Logs</LogButton>
		</LogContainer>
	)
}

export default Log
