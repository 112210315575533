import Ajv from 'ajv'
import schema from '../../../assets/json/DashboardInputValues-schema.json'
import type { AnalyticsCuePoints, Currency, TimestampAutomationCommand } from '../../../config/enums'
import type { Goals } from '../../../interfaces/Goals'
import type { BaseGameSettingsProps, BlockbusterSettingsProps, FeaturedGame } from './GamesSettings'
import type { DateTimeProps } from './NextShowTimeActions'
import type { TimestampProps } from './TimestampProps'

const ajv = new Ajv()

export interface DashboardInputValues {
	video: {
		highQualityVideoUrl: string
		videoUrl: string
		lowQualityVideoUrl: string
		guestScore: number
	}
	beatGuestMessages: {
		yesFirst: string
		yesSecond: string
		noFirst: string
		noSecond: string
	}
	prizes: {
		currency: Currency
		beatGuestWinner: number
		luckyWinner: number
		coLuckyWinner: number
		leagueWinner: { [league: string]: number }
	}
	blockbusterSettings: BlockbusterSettingsProps
	centipedeSettings: BaseGameSettingsProps
	beatSettings: BaseGameSettingsProps
	rallySettings: BaseGameSettingsProps
	breakoutSettings: BaseGameSettingsProps
	featuredGame: FeaturedGame
	nextShowDateTime: DateTimeProps
	nextDailyGoals: Goals
	nextWeeklyGoals: Goals
	nextMonthlyGoals: Goals
	guestInfo: {
		guestDetails: string
		guestDetailsPictureURL: string
		guestExternalURL: string
		guestRoomPosterPictureURL: string
		guestTitle: string
	}
	forceUpdateVersion: { android: string; iOS: string }
	timestampsAutomationSettings: Record<TimestampAutomationCommand, TimestampProps>
	analyticsCuePoints: Record<AnalyticsCuePoints, TimestampProps>
}

export const validateDashboardInputValues = ajv.compile<DashboardInputValues>(schema)
