import type { FunctionComponent, ReactElement } from 'react'
import styled, { css } from 'styled-components'

const LiveStatusIndicator = styled.div`
	display: inline-flex;
	margin-top: 10px;
	margin-bottom: -10px;
	padding: 10px;
	align-items: center;
`

const LiveCircle = styled.div<{ isLive: boolean }>`
	flex: 0 0 auto;
	width: 20px;
	height: 20px;
	background-color: grey;
	border-radius: 50%;
	display: inline-block;
	${(props) =>
		props.isLive &&
		css`
			animation: blink 2s infinite;
		`}

	@keyframes blink {
		0%,
		100% {
			background-color: red;
		}
		50% {
			background-color: grey;
		}
	}
`

const LiveStatusText = styled.span`
	margin-left: 10px;
`

const LiveStatusTextHighlighted = styled.span`
	font-weight: bold;
`

interface LiveStatusProps {
	isLive: boolean
}

function LiveStatus({ isLive }: LiveStatusProps): ReactElement<typeof LiveStatusIndicator, FunctionComponent<typeof LiveStatusIndicator>> {
	return (
		<LiveStatusIndicator>
			<LiveCircle isLive={isLive} />
			<LiveStatusText>
				Show is <LiveStatusTextHighlighted>{isLive ? 'ONLINE' : 'OFFLINE'}</LiveStatusTextHighlighted>
			</LiveStatusText>
		</LiveStatusIndicator>
	)
}

export default LiveStatus
