import type { FunctionComponent, ReactElement, ReactNode } from 'react'
import { Input, InputContainer, Slider, ToggleContainer, ToggleInputContainer } from './ToggleContainer'

interface Props {
	onChange: (status?: boolean) => void
	label: string
	status: boolean
	activeColor?: string
	isDisabled?: boolean
	className?: string
	children?: ReactNode
}

function Toggle({
	status,
	onChange,
	label,
	isDisabled,
	activeColor,
	className,
	children,
}: Props): ReactElement<typeof ToggleContainer, FunctionComponent<typeof ToggleContainer>> {
	return (
		<ToggleContainer className={className}>
			<label>{label}</label>
			<ToggleInputContainer>
				<InputContainer>
					<Input
						type="checkbox"
						onChange={() => {
							onChange()
						}}
						disabled={isDisabled}
						checked={status}
						active_color={activeColor}
					/>
					<Slider />
				</InputContainer>
				{children}
			</ToggleInputContainer>
		</ToggleContainer>
	)
}

export default Toggle
