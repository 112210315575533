import type { ChangeEvent, FunctionComponent, ReactElement } from 'react'
import { leagues } from '../../config/app'
import { Currency } from '../../config/enums'
import { CardBody, CardFieldSet, CardHeader, CardHeading, CardInput, CardWrapper } from '../../containers/Card'
import { usePrizesState } from '../../utils/hooks/dashboardInputs/usePrizes'
import { isNumberString } from '../../utils/isNumberString'

function Prizes(): ReactElement<typeof CardWrapper, FunctionComponent<typeof CardWrapper>> {
	const [currency, beatGuestWinner, luckyWinner, coLuckyWinner, leagueWinner, updatePrizesState] = usePrizesState()

	const onCurrencyChange = (event: ChangeEvent<HTMLSelectElement>) => {
		const {
			currentTarget: { value },
		} = event

		updatePrizesState({
			currency: value as Currency,
			beatGuestWinner: beatGuestWinner.value,
			luckyWinner: luckyWinner.value,
			coLuckyWinner: coLuckyWinner.value,
			leagueWinner: leagueWinner.value,
		})
	}

	const onBeatGuestWinnerChange = (event: ChangeEvent<HTMLInputElement>) => {
		const {
			currentTarget: { value },
		} = event

		if (!isNumberString(value)) return

		updatePrizesState({
			currency,
			beatGuestWinner: Number(value),
			luckyWinner: luckyWinner.value,
			coLuckyWinner: coLuckyWinner.value,
			leagueWinner: leagueWinner.value,
		})
	}

	const onLuckWinnerChange = (event: ChangeEvent<HTMLInputElement>) => {
		const {
			currentTarget: { value },
		} = event

		if (!isNumberString(value)) return

		updatePrizesState({
			currency,
			beatGuestWinner: beatGuestWinner.value,
			luckyWinner: Number(value),
			coLuckyWinner: coLuckyWinner.value,
			leagueWinner: leagueWinner.value,
		})
	}

	const onCoLuckyWinnerChange = (event: ChangeEvent<HTMLInputElement>) => {
		const {
			currentTarget: { value },
		} = event

		if (!isNumberString(value)) return

		updatePrizesState({
			currency,
			beatGuestWinner: beatGuestWinner.value,
			luckyWinner: luckyWinner.value,
			coLuckyWinner: Number(value),
			leagueWinner: leagueWinner.value,
		})
	}

	const onLeagueWinnerChange = (league: string) => (event: ChangeEvent<HTMLInputElement>) => {
		const {
			currentTarget: { value },
		} = event

		if (!isNumberString(value)) return

		updatePrizesState({
			currency,
			beatGuestWinner: beatGuestWinner.value,
			luckyWinner: luckyWinner.value,
			coLuckyWinner: coLuckyWinner.value,
			leagueWinner: { ...leagueWinner.value, [league]: Number(value) },
		})
	}

	const currencyList = Object.values(Currency)

	return (
		<CardWrapper>
			<CardHeader>
				<CardHeading>Prizes</CardHeading>
			</CardHeader>
			<CardBody>
				<CardFieldSet>
					<label>Currency:&nbsp;</label>
					<select value={currency} onChange={onCurrencyChange}>
						{currencyList.map((currency) => (
							<option key={`${currency}`} value={currency}>
								{currency}
							</option>
						))}
					</select>
				</CardFieldSet>
				{leagues.map((league) => (
					<CardFieldSet key={league}>
						<label>{league} Winner</label>
						<CardInput
							value={leagueWinner.value[league]}
							isValid={leagueWinner.isValid}
							type="text"
							onChange={onLeagueWinnerChange(league)}
							placeholder="{league} Winner"
						/>
					</CardFieldSet>
				))}
				<CardFieldSet>
					<label>Lucky Winner</label>
					<CardInput
						value={luckyWinner.value}
						isValid={luckyWinner.isValid}
						type="text"
						onChange={onLuckWinnerChange}
						placeholder="Lucky Winner"
					/>
				</CardFieldSet>
				<CardFieldSet>
					<label>Co-Lucky Winner</label>
					<CardInput
						value={coLuckyWinner.value}
						isValid={coLuckyWinner.isValid}
						type="text"
						onChange={onCoLuckyWinnerChange}
						placeholder="Co-Lucky Winner"
					/>
				</CardFieldSet>
				<CardFieldSet>
					<label>Beat-the-Guest Winner</label>
					<CardInput
						value={beatGuestWinner.value}
						isValid={beatGuestWinner.isValid}
						type="text"
						onChange={onBeatGuestWinnerChange}
						placeholder="Beat-the-Guest Winner"
					/>
				</CardFieldSet>
			</CardBody>
		</CardWrapper>
	)
}

export default Prizes
