import type { ChangeEvent, FunctionComponent, ReactElement } from 'react'
import type { CSSProperties } from 'styled-components'
import { enumKeys } from '../../utils/enumKeys'

interface Props {
	keyElement: string
	name?: string
	selectedEnum: number
	onChangeEnumSelect: (event: ChangeEvent<HTMLSelectElement>) => void
	Enum: Record<string, unknown>
	style?: CSSProperties
}

function EnumSelect({
	keyElement,
	name,
	selectedEnum,
	onChangeEnumSelect,
	Enum,
	style,
}: Props): ReactElement<typeof HTMLElement, FunctionComponent<typeof HTMLElement>> {
	return (
		<select
			name={name || keyElement}
			id={keyElement}
			value={selectedEnum}
			onChange={onChangeEnumSelect}
			style={{
				width: '15%',
				marginLeft: '5%',
				marginRight: '5%',
				...style,
			}}
		>
			{enumKeys(Enum).map((key, index) => {
				const keyValue = Enum[key] as number

				return (
					<option key={`${keyElement}-option-${index}`} value={keyValue}>
						{key}
					</option>
				)
			})}
		</select>
	)
}

export default EnumSelect
