import type { ChangeEvent, FunctionComponent, ReactElement } from 'react'
import styled from 'styled-components'
import { showTypes } from '../../config/app'
import type { ShowType } from '../../config/enums'

const ShowTypeContainer = styled.div`
	margin-top: 20px;
	align-items: center;

	select {
		max-width: 100%;
	}
`

interface ShowTypeProps {
	showType: ShowType
	onChangeShowType: (event: ChangeEvent<HTMLSelectElement>) => void
}

function ShowTypeSelect({
	showType,
	onChangeShowType,
}: ShowTypeProps): ReactElement<typeof ShowTypeContainer, FunctionComponent<typeof ShowTypeContainer>> {
	return (
		<ShowTypeContainer>
			<select name="showType" id="showType" value={showType} onChange={onChangeShowType}>
				{showTypes.map(({ key, name }, index) => (
					<option key={`${showType}-${index}`} value={key}>
						{name}
					</option>
				))}
			</select>
		</ShowTypeContainer>
	)
}

export default ShowTypeSelect
