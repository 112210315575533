import 'react-widgets/styles.css'
import type { FunctionComponent, ReactElement } from 'react'
import type { StateTree } from '../../redux/interfaces'
import { useAppSelector } from '../../utils/hooks'
import { useShowCalendar } from '../../utils/hooks/useShowCalendar'
import { Calendar } from './Calendar'
import RenderDay from './RenderDay'

function ShowCalendar(): ReactElement<typeof Calendar, FunctionComponent<typeof Calendar>> {
	const [showCalendarState, onNavigate, onChange] = useShowCalendar()
	const { selectedDate, isLoading } = showCalendarState
	const { saveScheduledShowInfo, saveShowInfo } = useAppSelector(({ commands: { saveScheduledShowInfo, saveShowInfo } }: StateTree) => ({
		saveScheduledShowInfo,
		saveShowInfo,
	}))

	return (
		<Calendar
			disabled={isLoading || saveScheduledShowInfo.isLoading || saveShowInfo.isLoading}
			views={['month']}
			value={new Date(selectedDate)}
			renderDay={RenderDay}
			onNavigate={onNavigate}
			onChange={onChange}
		/>
	)
}

export default ShowCalendar
