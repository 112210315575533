import type { ChangeEvent, FunctionComponent, ReactElement } from 'react'
import { shots } from '../../config/app'
import type { Shots } from '../../interfaces/Shots'

interface Props {
	value: Shots
	keyValue: string
	shotNumber: number
	onChange: (event: ChangeEvent<HTMLSelectElement>, index: number) => void
}

function ShotSelect({ value, keyValue, shotNumber, onChange }: Props): ReactElement<typeof HTMLElement, FunctionComponent<typeof HTMLElement>> {
	return (
		<select
			name={keyValue}
			id={keyValue}
			value={value[shotNumber]}
			onChange={(event) => {
				onChange(event, shotNumber)
			}}
		>
			{shots.map((name, index) => (
				<option key={`${keyValue}${shotNumber}-${index}`} value={index}>
					{`${index}. ${name}`}
				</option>
			))}
		</select>
	)
}

export default ShotSelect
