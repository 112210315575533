import type { ChangeEvent, FunctionComponent, ReactElement } from 'react'
import { CardBody, CardButton, CardFieldSet, CardHeader, CardHeading, CardWrapper } from '../../containers/Card'
import { TimeFrame } from '../../interfaces/TimeFrame'
import { useNextGoalsOverwriteCommand } from '../../utils/hooks/commands/useNextGoalsOverwriteCommand'
import { useNextMonthlyGoalsState } from '../../utils/hooks/dashboardInputs/useNextMonthlyGoals'
import { getOrdinal } from '../../utils/ordinal/getOrdinal'
import GoalSelect from '../GoalSelect'
import Toggle from '../Toggle'

function NextMonthlyGoals(): ReactElement<typeof CardWrapper, FunctionComponent<typeof CardWrapper>> {
	const [nextMonthlyGoals, updateNextMonthlyGoalsState, toggleReset] = useNextMonthlyGoalsState()
	const [nextGoalsOverwriteState, overwriteNextGoalsCommand] = useNextGoalsOverwriteCommand(TimeFrame.Monthly)

	const onChangeNextMonthlyGoals = (event: ChangeEvent<HTMLSelectElement>, goalNumber: number) => {
		const {
			currentTarget: { value },
		} = event

		const newMonthlyGoals = { ...nextMonthlyGoals.value, [goalNumber]: JSON.parse(value) }

		updateNextMonthlyGoalsState({
			...newMonthlyGoals,
		})
	}

	return (
		<CardWrapper>
			<CardHeader>
				<CardHeading>Next Monthly Goals</CardHeading>
			</CardHeader>
			<CardBody>
				{Array(5)
					.fill('')
					.map((_, index) => {
						const rootKey = `nextMonthlyGoals-${index}`
						return (
							<CardFieldSet key={rootKey}>
								<label>{getOrdinal(index + 1) + ': '}</label>
								<GoalSelect
									timeFrame={TimeFrame.Monthly}
									value={nextMonthlyGoals.value}
									goalNumber={index}
									rootKey={rootKey}
									onChange={onChangeNextMonthlyGoals}
								/>
							</CardFieldSet>
						)
					})}
				<CardFieldSet>
					<Toggle label="Reset" status={nextMonthlyGoals.reset} onChange={toggleReset} />
					<CardButton type="button" disabled={nextGoalsOverwriteState.isUpdating} onClick={overwriteNextGoalsCommand}>
						Overwrite
					</CardButton>
				</CardFieldSet>
			</CardBody>
		</CardWrapper>
	)
}

export default NextMonthlyGoals
